var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chinese"},[_c('div',{staticClass:"bread"},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/academic' }}},[_vm._v("中文期刊推荐")]),_c('el-breadcrumb-item',[_vm._v("期刊推荐")])],1)],1),_c('div',{staticClass:"box"},[_c('div',{staticClass:"header"},[_c('h2',[_vm._v("期刊检索：")]),_c('div',{staticClass:"input"},[_c('el-input',{attrs:{"placeholder":"请输入检索字段","clearable":""},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}},[_c('el-select',{attrs:{"slot":"prepend","placeholder":"请选择"},slot:"prepend",model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}},[_c('el-option',{attrs:{"label":"刊名","value":"name"}}),_c('el-option',{attrs:{"label":"ISSN","value":"issn"}}),_c('el-option',{attrs:{"label":"篇名","value":"title"}}),_c('el-option',{attrs:{"label":"DOI","value":"doi"}})],1)],1),_c('el-button',{attrs:{"size":"","type":"primary","round":""},on:{"click":_vm.onSubmit}},[_vm._v("搜索")])],1)]),_c('div',{staticClass:"search_field"}),_c('el-divider'),(_vm.journalOr && !_vm.journalSearchList)?_c('div',[(_vm.comprehensive.length == 0)?_c('div',{staticClass:"not01"},[_c('img',{attrs:{"src":require("../../../assets/imgs/zanwushuj.png"),"alt":""}})]):_vm._e(),_vm._l((_vm.comprehensive),function(item,index){return _c('div',{key:index,staticClass:"list"},[_c('div',{staticClass:"title"},[_c('div',[_c('h2',{},[_vm._v(_vm._s(item.name)+"：")])]),_c('router-link',{attrs:{"to":{
              path: '/journalsList',
              query: { subject: item.name, language: _vm.language ,webPage: 1,},
            }}},[_c('span',[_vm._v("更多>>")])])],1),_c('div',{staticClass:"journals_list"},[_c('el-carousel',{attrs:{"indicator-position":"outside","autoplay":false,"arrow":"always"}},_vm._l((item.data),function(item_data,index){return _c('el-carousel-item',{key:index},[_c('div',{staticClass:"cover_imgs"},_vm._l((item_data),function(item_single,index){return _c('div',{key:'single' + index,staticClass:"imgs",on:{"click":function($event){return _vm.goDetail(item_single)}}},[_c('img',{attrs:{"src":'https://push.newacademic.net/cover/journal_cover/' +
                      (item_single.language == 1
                        ? item_single.name
                        : item_single.cn_name) +
                      '.jpg',"alt":""}}),(item_single.language == '1')?_c('h4',{staticClass:"bookTitle",on:{"click":function($event){return _vm.goDetail(item_single)}}},[_vm._v(" "+_vm._s(item_single.name)+" ")]):_vm._e(),(item_single.language == '0')?_c('h4',{staticClass:"bookTitle",on:{"click":function($event){return _vm.goDetail(item_single)}}},[_vm._v(" "+_vm._s(item_single.cn_name)+" ")]):_vm._e()])}),0)])}),1)],1)])})],2):_vm._e(),(_vm.journalSearchList)?_c('div',[_vm._l((_vm.searchList.slice(
          10 * _vm.pag,
          10 * _vm.currentPage1 - 1
        )),function(item,index){return _c('div',{key:index,staticClass:"journal_list"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"journal_img"},[_c('img',{attrs:{"src":'https://push.newacademic.net/cover/journal_cover/' +
              (item.language == 1 ? item.name : item.cn_name) +
              '.jpg',"alt":""},on:{"click":function($event){return _vm.goDetail(item)}}}),(item.isReads == '1')?_c('div',{staticClass:"item"}):_vm._e()]),_c('div',{staticClass:"journal_data"},[_c('div',{staticClass:"title01"},[_c('el-tag',{staticStyle:{"margin":"0 20px 0 0"}},[_vm._v(_vm._s(item.categoryType))]),(item.language == 1)?_c('h2',{on:{"click":function($event){return _vm.goDetail(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(item.language == 0)?_c('h2',{on:{"click":function($event){return _vm.goDetail(item)}}},[_vm._v(" "+_vm._s(item.cn_name)+" ")]):_vm._e()],1),_c('div',{staticClass:"tags"},_vm._l((item.source),function(item,index){return _c('div',{key:index},[(item == 'CA 化学文摘(美)(2020)')?_c('el-tag',[_vm._v("CA")]):_vm._e(),(
                  item == 'CSCD 中国科学引文数据库来源期刊(2021-2022年度)'
                )?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("CSCD")]):_vm._e(),(
                  item ==
                  'CSCD 中国科学引文数据库来源期刊(2021-2022年度)(扩展版)'
                )?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("CSCD(扩)")]):_vm._e(),(item == 'CSSCI 中文社会科学引文索引(2021-2022)来源期刊')?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("CSSCI")]):_vm._e(),(item == 'CSTPCD')?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("CSTPCD")]):_vm._e(),(item == 'JST 日本科学技术振兴机构数据库(日)(2018)')?_c('el-tag',[_vm._v("JST")]):_vm._e(),(item == 'Pж(AJ) 文摘杂志(俄)(2020)')?_c('el-tag',{attrs:{"type":"success"}},[_vm._v("Pж(AJ) ")]):_vm._e(),(item == 'SA 科学文摘(英)(2020)')?_c('el-tag',{attrs:{"type":"info"}},[_vm._v("SA")]):_vm._e(),(
                  item == 'WJCI 科技期刊世界影响力指数报告（2020）来源期刊'
                )?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("WJCI")]):_vm._e(),(item == '北大核心')?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("北大核心")]):_vm._e(),(item == 'EI 工程索引(美)(2020)')?_c('el-tag',{attrs:{"type":"warning"}},[_vm._v("EI")]):_vm._e(),(item == 'SCI 科学引文索引(美)(2020)')?_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("SCI")]):_vm._e()],1)}),0),_c('p',[_vm._v("创办单位："+_vm._s(item.press))]),_c('p',[_vm._v("出版周期："+_vm._s(item.frequency))]),_c('p',[_vm._v("创建时间："+_vm._s(_vm._f("parseTime")(item.create_time,"{y}-{m}-{d}")))]),_c('p',[_vm._v(" 最新更新时间："+_vm._s(_vm._f("parseTime")(item.update_time,"{y}-{m}-{d}"))+" ")])])]),_c('el-divider',{staticStyle:{"width":"100%"}})],1)}),(_vm.totalCount > 10)?_c('div',{staticClass:"page-bar"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.totalCount > 10000 ? 10000 : _vm.totalCount,"page-size":10,"current-page":_vm.currentPage1},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage1=$event},"update:current-page":function($event){_vm.currentPage1=$event}}})],1):_vm._e()],2):_vm._e(),(!_vm.journalOr)?_c('div',{staticClass:"articleList"},[(_vm.article.length == 0)?_c('div',{staticClass:"not"},[_c('img',{attrs:{"src":require("../../../assets/imgs/zanwushuj.png"),"alt":""}})]):_vm._e(),_vm._l((_vm.article),function(item,index){return _c('div',{key:index,staticClass:"article_list"},[_c('div',{staticClass:"title"},[_c('div',[(item._source.title !== '' && item._source.language == '1')?_c('h2',{staticClass:"titleH",on:{"click":function($event){return _vm.goEssay(item)}}},[_vm._v(" "+_vm._s(item._source.title)+" ")]):_vm._e(),(
                item._source.ch_title !== '' && item._source.language == '0'
              )?_c('h2',{on:{"click":function($event){return _vm.goEssay(item)}}},[_vm._v(" "+_vm._s(item._source.ch_title)+" ")]):_vm._e(),(
                item._source.ch_title !== '' && item._source.language == '1'
              )?_c('h2',{staticStyle:{"font-size":"15px"}},[_vm._v(" "+_vm._s(item._source.ch_title)+" ")]):_vm._e()])]),_c('p',_vm._l((item._source.author),function(item_author,index){return _c('span',{key:index},[_vm._v(_vm._s(item_author)+" ；")])}),0),(
            item._source.ch_keyword.length > 0 && item._source.language == '0'
          )?_c('p',_vm._l((item._source.ch_keyword),function(item_keyword,index){return _c('span',{key:index},[_vm._v(_vm._s(item_keyword)+" ；")])}),0):_vm._e(),(
            item._source.keyword.length > 0 && item._source.language == '1'
          )?_c('p',_vm._l((item._source.keyword),function(item_keyword,index){return _c('span',{key:index},[_vm._v(_vm._s(item_keyword)+" ；")])}),0):_vm._e(),_c('p',[(item._source.journal_name !== '')?_c('span',{staticStyle:{"width":"50%"}},[_vm._v(_vm._s(item._source.journal_name)+" ")]):_vm._e(),_c('span',{staticStyle:{"width":"50%"}},[(item._source.year !== '')?_c('span',[_vm._v(_vm._s(item._source.year)+"年")]):_vm._e(),(item._source.vol !== '')?_c('span',[_vm._v("第"+_vm._s(item._source.vol)+"卷")]):_vm._e(),(item._source.issue !== '')?_c('span',[_vm._v("第"+_vm._s(item._source.issue)+"卷")]):_vm._e()])]),(
            item._source.ch_abstract.length > 0 &&
            item._source.language == '0'
          )?_c('p',[(item._source.ch_abstract.length > 150 && !_vm.more)?_c('span',[_vm._v(_vm._s(item._source.ch_abstract.slice(0, 150))+" "),_c('span',{staticClass:"more_text"},[_vm._v("...")])]):_vm._e()]):_vm._e(),(
            item._source.abstract.length > 0 && item._source.language == '1'
          )?_c('p',[(item._source.abstract.length > 150 && !_vm.more)?_c('span',[_vm._v(_vm._s(item._source.abstract.slice(0, 150))+" "),_c('span',{staticClass:"more_text"},[_vm._v("...")])]):_vm._e()]):_vm._e(),(item._source.ch_abstract.length > 0)?_c('p',[(_vm.more)?_c('span',[_vm._v(_vm._s(item._source.ch_abstract)+" "),_c('span',{staticClass:"more_text"},[_vm._v(" 收起")])]):_vm._e()]):_vm._e(),_c('el-divider',{staticStyle:{"margin":"30px 0"}})],1)}),(_vm.totalCount01 > 0)?_c('div',{staticClass:"page-bar"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","total":_vm.totalCount01 > 10000 ? 10000 : _vm.totalCount01,"page-size":10},on:{"current-change":_vm.handleCurrentChange01}})],1):_vm._e()],2):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }