<template>
    <div class="academic_box">
        <!-- 学术推送 -->
        <el-container v-bind:style="{ height: Height + 'px' }">
            <el-main v-bind:style="{ height: Height + 'px' }">
                <CHINESE v-if="keys == '1'"></CHINESE>
                <div v-else-if="keys == '2'">
                    <ENGLISH></ENGLISH>
                </div>
                <PERSONALIZE v-else-if="keys == '4' && subscribeOr == '1'"></PERSONALIZE>
                <SUBSCRIBE v-else="keys == '3'"></SUBSCRIBE>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import CHINESE from "./component/chineseJournals.vue";
import ENGLISH from "./component/englishJournals.vue";
import PERSONALIZE from "./component/personalize.vue";
import SUBSCRIBE from "./component/subscribe.vue";
import { update_article } from "@/Api/journals";
export default {
    // inject: ["reloads"],
    provide() {
        return {
            reloading: this.reloading,
            imgs: require("../../assets/imgs/icons/杂志期刊.svg"),
            // Height: 0,
        };
    },
    data() {
        return {
            Height: 0,
            keys: "1",
            activeIndex: "1",
            isRouterAlive: true,
            total: null,
            subscribeOr: localStorage.getItem("subscribeOr"),
        };
    },
    mounted() {
        //动态设置内容高度 让footer始终居底   header+footer的高度是190
        // console.log(this.name)
        this.Height = document.documentElement.clientHeight - 80; //监听浏览器窗口变化
        window.onresize = () => {
            this.Height = document.documentElement.clientHeight - 80;
        };
    },
    created() {
        // this.unreads();
        // console.log('or',this.subscribeOr)
        if (this.$route.query.webPage) {
            this.keys = this.$route.query.webPage.toString();
            this.activeIndex = this.$route.query.webPage.toString();
        }
        // if (window.sessionStorage.getItem("webPage")) {
        //   this.keys = window.sessionStorage.getItem("webPage");
        //   // console.log(this.keys)
        // } else {
        //   this.keys = "1";
        //   window.sessionStorage.setItem("webPage", this.keys);
        // }
        this.update();
    },
    // 监听路由变化
    watch: {
        $route(to, from) {
            if (to.query.webPage) {
                this.keys = to.query.webPage.toString();
            }
        },
    },
    methods: {
        reloading() {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        handleSelect(key) {
            // console.log( key);
            this.keys = key;
            this.$router.push({
                name: "Academic",
                query: { webPage: key },
            });
            // if (window.sessionStorage.getItem("el_tabs_index")) {
            //   this.activeName = window.sessionStorage.removeItem("el_tabs_index");
            // }
            // console.log(this.$route.query.search)
            if (this.$route.query.search) {
                this.$router.push({
                    path: "/academic",
                    query: { webPage: key },
                });
                // console.log('a')
            }
            // window.sessionStorage.setItem("webPage", this.keys);

            // if (this.keys == 1) {
            //   this.reloads();
            // }
            // if (this.keys == 2) {
            //   this.reloading();
            // }
        },
        update() {
            let user_id = {
                user_openid: localStorage.getItem("code"),
            };
            update_article(user_id)
                .then((res) => {
                    // console.log("id",res);
                    this.total = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    components: {
        CHINESE,
        ENGLISH,
        SUBSCRIBE,
        PERSONALIZE,
    },
};
</script>

<style lang="less" scoped>
.academic_box {
    min-width: 1360px;

    .el-aside {
        background-color: #fff;
        color: #333;
        text-align: center;
        line-height: 200px;
    }

    .el-main {
        // height: 100%;
        background-color: #e9eef3;
        color: #333;
        // text-align: center;
        // line-height: 160px;
    }

    body>.el-container {
        margin-bottom: 40px;
    }

    .el-container:nth-child(5) .el-aside,
    .el-container:nth-child(6) .el-aside {
        line-height: 260px;
    }

    .el-container:nth-child(7) .el-aside {
        line-height: 320px;
    }

    .el-menu {
        background-color: #fff;
    }
}

.is-active div {
    background-color: #B14A75;

    span {
        color: #fff;
    }

    i {
        color: #fff;
    }
}

.el-menu-item {
    padding: 5px 50px !important;
    background-color: #fff;
    margin-bottom: 5px;
}

.aside_item {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-radius: 5px;

    span {
        color: #b1aec8;
        font-size: 15px;
    }
}

.item {
    line-height: 30px;

    /deep/ .el-badge__content.is-fixed {
        position: absolute;
        top: 5px;
        right: 13px;
        transform: translateY(-50%) translateX(100%);
    }
}

.item {
    line-height: 30px;

    /deep/ .el-badge__content.is-fixed {
        position: absolute;
        top: 0px;
        right: 13px;
        transform: translateY(-50%) translateX(100%);
    }
}
</style>