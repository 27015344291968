<template>
    <div class="subscribe">
        <div class="personal">
            <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
                <el-tab-pane name="0">
                    <span slot="label"><i class="el-icon-collection-tag"></i> 我的订阅</span>
                    <div class="checks">
                        <div class="cen">
                            <h3 style="
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: 35px;
                ">
                                <span style="display: inline-block; width: 100%">我的订阅</span>
                                <el-button type="primary" size="mini" plain style="" v-if="total > 0"
                                    @click="allReads()">一键已读</el-button>
                            </h3>
                            <div v-if="listData.length == 0" class="not">
                                <img src="../../../assets/imgs/zanwushuj.png" alt="" />
                            </div>
                            <div v-for="(item, index) in listData" :key="index" class="journal_list">
                                <div class="journal_img">
                                    <img :src="'https://push.newacademic.net/cover/journal_cover/' +
                (item.language == 1 ? item.name : item.cn_name) +
                '.jpg'
                " alt="" @click="goDetail(item)" />
                                    <img v-if="item.personalized == 1" class="subscribe_css"
                                        src="../../../assets/imgs/icons/customized1.png" alt="" />
                                    <div class="item" v-if="item.isReads == '1'"></div>
                                </div>
                                <div class="journal_data">
                                    <div class="title">
                                        <!-- <router-link
                :to="{ path: '/journalsDetail', query: { id: item.id } }"
                v-if="item.language == 1"
                > -->
                                        <h2 v-if="item.language == 1" @click="goDetail(item)">
                                            {{ item.name }}
                                        </h2>
                                        <!-- </router-link
              > -->
                                        <!-- <router-link
                :to="{
                  path: '/journalsDetail',
                  query: { id: item.id, subject: item.categories },
                }"
                v-if="item.language == 0"
                > -->
                                        <h2 v-if="item.language == 0" @click="goDetail(item)">
                                            {{ item.cn_name }}
                                        </h2>
                                        <!-- </router-link
              > -->
                                        <el-popconfirm title="确认取消订阅吗？" @confirm="subscribeSubject(item)">
                                            <el-button slot="reference" type="primary" size="mini" plain
                                                style="background: #b14a75; color: white">已订阅</el-button>
                                        </el-popconfirm>
                                    </div>

                                    <div class="tags">
                                        <div v-for="(item, index) in item.source" :key="index">
                                            <el-tag v-if="item == 'CA 化学文摘(美)(2020)'">CA</el-tag>
                                            <el-tag v-if="item ==
                'CSCD 中国科学引文数据库来源期刊(2021-2022年度)'
                " type="success">CSCD</el-tag>
                                            <el-tag v-if="item ==
                'CSCD 中国科学引文数据库来源期刊(2021-2022年度)(扩展版)'
                " type="info">CSCD(扩)</el-tag>
                                            <el-tag v-if="item ==
                'CSSCI 中文社会科学引文索引(2021-2022)来源期刊'
                " type="warning">CSSCI</el-tag>
                                            <el-tag v-if="item == 'CSTPCD'" type="danger">CSTPCD</el-tag>
                                            <el-tag v-if="item == 'JST 日本科学技术振兴机构数据库(日)(2018)'
                ">JST</el-tag>
                                            <el-tag v-if="item == 'Pж(AJ) 文摘杂志(俄)(2020)'" type="success">Pж(AJ)
                                            </el-tag>
                                            <el-tag v-if="item == 'SA 科学文摘(英)(2020)'" type="info">SA</el-tag>
                                            <el-tag v-if="item ==
                'WJCI 科技期刊世界影响力指数报告（2020）来源期刊'
                " type="warning">WJCI</el-tag>
                                            <el-tag v-if="item == '北大核心'" type="danger">北大核心</el-tag>
                                            <el-tag v-if="item == 'EI 工程索引(美)(2020)'" type="warning">EI</el-tag>
                                            <el-tag v-if="item == 'SCI 科学引文索引(美)(2020)'" type="danger">SCI</el-tag>
                                        </div>
                                    </div>
                                    <p>创办单位：{{ item.press }}</p>
                                    <p>出版周期：{{ item.frequency }}</p>
                                    <p>
                                        创建时间：{{ item.create_time | parseTime("{y}-{m}-{d}") }}
                                    </p>
                                    <p>
                                        最新更新时间：{{
                item.update_time | parseTime("{y}-{m}-{d}")
            }}
                                    </p>
                                </div>
                            </div>
                            <div class="page-bar" v-if="totalCount > 10">
                                <el-pagination background layout="prev, pager, next"
                                    :total="totalCount > 10000 ? 10000 : totalCount"
                                    @current-change="handleCurrentChange" :page-size="10">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="1">
                    <span slot="label"><i class="el-icon-star-off"></i> 我的收藏</span>
                    <div class="proof">
                        <div class="cen">
                            <h3 style="margin-bottom: 35px">我的收藏</h3>
                            <div v-if="article.length == 0" class="not">
                                <img src="../../../assets/imgs/zanwushuj.png" alt="" />
                            </div>
                            <div class="articleList">
                                <div class="article_list" v-for="(item, index01) in article" :key="index01">
                                    <div class="title">
                                        <div>
                                            <h2 v-if="item._source.journal_language == '0'" @click="goEssay(item)">
                                                {{ item._source.ch_title }}
                                            </h2>
                                            <h2 v-if="item._source.journal_language == '1'" @click="goEssay(item)">
                                                {{ item._source.title }}
                                            </h2>
                                        </div>
                                        <div>
                                            <el-popconfirm title="确认取消收藏吗？" @confirm="collectionCollect(item._id)">
                                                <img slot="reference" src="../../../assets/imgs/icons/collect1.png"
                                                    alt="" />
                                            </el-popconfirm>
                                        </div>
                                    </div>

                                    <p>
                                        <!-- <img src="../../../assets/imgs/icons/photo.png" alt="" /> -->
                                        <span v-for="(item_author, index) in item._source.author" :key="index">{{
                item_author }} ；</span>
                                    </p>
                                    <p v-if="item._source.ch_keyword.length > 0 &&
                item._source.language == '0'
                ">
                                        <!-- <img src="../../../assets/imgs/icons/journalsci.png" alt="" /> -->
                                        <span v-for="(item_keyword, index) in item._source.ch_keyword" :key="index">{{
                item_keyword }} ；</span>
                                    </p>
                                    <p v-if="item._source.keyword.length > 0 &&
                item._source.language == '1'
                ">
                                        <!-- <img src="../../../assets/imgs/icons/journalsci.png" alt="" /> -->
                                        <span v-for="(item_keyword, index) in item._source.keyword" :key="index">{{
                item_keyword }} ；</span>
                                    </p>
                                    <p>
                                        <!-- <img src="../../../assets/imgs/icons/journalsci.png" alt="" /> -->
                                        <span v-if="item._source.journal_name !== ''" :style="item._source.journal_name !== ''
                    ? ''
                    : 'margin:0 50px 0 0 ;'
                ">{{ item._source.journal_name }}
                                        </span>
                                        <span style="margin: 0 0px"><span v-if="item._source.year !== ''">{{
                item._source.year }}年</span><span v-if="item._source.vol !== ''">第{{
                item._source.vol }}卷</span><span v-if="item._source.issue !== ''">第{{
                item._source.issue }}卷</span></span>
                                    </p>
                                    <el-divider></el-divider>
                                    <!-- <p v-if="item._source.ch_abstract.length > 0 && item._source.journal_language == '0'">
              <span v-if="item._source.ch_abstract.length > 100 && !more"
                >{{ item._source.ch_abstract.slice(0, 100) }}
                <span class="more_text" @click="moreText(index01)">...更多</span></span
              >
            </p>
            <p v-if="item._source.ch_abstract.length > 0  && item._source.journal_language == '0'">
              <span v-if="more"
                >{{ item._source.ch_abstract }}
                <span class="more_text" @click="moreText(index01)"> 收起</span></span
              >
            </p>
            <p v-if="item._source.abstract.length > 0 && item._source.journal_language == '1'">
              <span v-if="item._source.abstract.length > 100 && !more"
                >{{ item._source.abstract.slice(0, 100) }}
                <span class="more_text" @click="moreText(index01)">...更多</span></span
              >
            </p>
            <p v-if="item._source.abstract.length > 0  && item._source.journal_language == '1' ">
              <span v-if="more"
                >{{ item._source.abstract }}
                <span class="more_text" @click="moreText(index01)"> 收起</span></span
              >
            </p> -->
                                    <!-- <el-divider></el-divider> -->
                                </div>
                                <div class="page-bar" v-if="totalCount01 > 0">
                                    <el-pagination background layout="prev, pager, next"
                                        :total="totalCount01 > 10000 ? 10000 : totalCount01"
                                        @current-change="handleCurrentChange01" :page-size="10">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane v-if="subscribeOr == '1'" name="2">
                    <span slot="label"><i class="el-icon-copy-document"></i> 传递列表</span>
                    <div class="proof">
                        <div class="cen">
                            <h3 style="margin-bottom: 35px">传递列表</h3>
                            <div v-if="emailList.length == 0" class="not">
                                <img src="../../../assets/imgs/zanwushuj.png" alt="" />
                            </div>
                            <div class="articleList">
                                <div class="article_list" v-for="(item, index02) in emailList" :key="index02">
                                    <div class="title">
                                        <div>
                                            <h4>
                                                {{ item.title }}
                                            </h4>
                                        </div>
                                        <div style="margin: 0 0 0 20px">
                                            <el-button type="danger" round v-if="item.status == 0">已失效</el-button>
                                            <el-button type="warning" round
                                                v-if="item.status !== 0 && item.pdf_link == ''">处&ensp;理&ensp;中</el-button>
                                            <el-button type="primary" round
                                                v-if="item.status !== 0 && item.pdf_link !== ''"
                                                @click="openOriginal(item)">查看原文</el-button>
                                        </div>
                                    </div>
                                    <p>
                                        <span>
                                            <!-- 传递时间: -->
                                            {{ item.email }}
                                        </span>
                                    </p>
                                    <p>
                                        <span>
                                            <!-- 传递时间: -->
                                            {{
                                            item.create_time | parseTime("{y}-{m}-{d} {h}:{i}:{s}")
                                            }}
                                        </span>
                                    </p>
                                    <el-divider></el-divider>
                                </div>
                                <div class="page-bar" v-if="totalCount02 > 0">
                                    <el-pagination background layout="prev, pager, next"
                                        :total="totalCount02 > 10000 ? 10000 : totalCount02"
                                        @current-change="handleCurrentChange02" :page-size="10">
                                    </el-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import {
    subscribe_list,
    collect_list,
    subscribe_subject,
    cancel_subscribe,
    cancel_collect,
    reads,
    been_read,
    update_article,
    email_list,
    downloadpdf,
} from "@/Api/journals";
export default {
    data() {
        return {
            subList: {
                user_openid: localStorage.getItem("code"),
                page: 1,
                num: 10,
            },
            colList: {
                user_openid: localStorage.getItem("code"),
                page: 1,
                num: 10,
            },
            listData: [],
            totalCount: 0,
            article: [],
            totalCount01: 0,
            more: false,
            total: null,
            one: "",
            email_pag: {
                page: 1,
                num: 10,
            },
            emailList: [],
            totalCount02: 0,
            subscribeOr: localStorage.getItem("subscribeOr"),
            activeName: "0",
        };
    },
    created() {
        if (window.sessionStorage.getItem("el_tabs_index")) {
            this.activeName = window.sessionStorage.getItem("el_tabs_index");
        }
        this.subscribe();
        this.collection();
        this.update();
        this.getEmail();
    },
    methods: {
        subscribe() {
            subscribe_list(this.subList)
                .then((res) => {
                    // console.log(res);
                    this.listData = [];
                    this.totalCount = 0;
                    this.listData = res.data.data.data;
                    this.listData.forEach((item) => {
                        item.source = item.source.split(";;");
                    });
                    //   console.log(this.listData);
                    this.totalCount = res.data.data.count;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 获取邮箱传递
        getEmail() {
            email_list(this.email_pag)
                .then((res) => {
                    // console.log(res);
                    // console.log( res.data.data.count)
                    this.emailList = res.data.data.data;
                    this.totalCount02 = res.data.data.count;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        // 翻页
        handleCurrentChange(val) {
            this.subList.page = val;
            this.subscribe();
            //   window.scrollTo(0, 0);
        },
        // 订阅期刊
        subscribeSubject(state) {
            let id = {
                user_openid: localStorage.getItem("code"),
                journal_id: state.id,
            };

            //   if (state.isSubscribe == 1) {
            //   console.log('a')
            cancel_subscribe(id)
                .then((res) => {
                    // console.log(res);
                    this.$message({
                        message: "订阅已取消",
                        //   type: "success",
                    });
                    this.subscribe();
                })
                .catch((err) => {
                    console.log(err);
                });
            //   }
            //   this.getList();
        },
        collection() {
            collect_list(this.colList)
                .then((res) => {
                    this.article = [];
                    this.totalCount01 = [];
                    if (res.data && res.data.data) {
                        this.article = res.data.data.data;
                        this.totalCount01 = res.data.data.count;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        moreText(index) {
            this.more = !this.more;
            // console.log(this.more);
            if (this.more) {
                this.one = index;
            } else {
                this.one = "";
            }
            // console.log(typeof this.one);
        },
        goEssay(data) {
            this.$router.push({
                path: "/essay",
                query: {
                    id: data._id,
                    journal_id: data._source.journal_id,
                },
            });
            // if (data._source.journal_language == "0") {
            //   this.$router.push({
            //     path: "/essay",
            //     query: {
            //       id: data._id,
            //       // journal: this.dataAll.cn_name,
            //       // type: this.$route.query.subject,
            //     },
            //   });
            // } else {
            //   this.$router.push({
            //     path: "/essay",
            //     query: {
            //       id: data._id,
            //       // journal: this.dataAll.name,
            //       // type: this.$route.query.subject,
            //     },
            //   });
            // }
        },
        openOriginal(link) {
            let down_id = {
                article_id: link.article_id,
            };
            downloadpdf(down_id)
                .then((res) => {
                    // console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
            // return
            window.open(link.pdf_link, "_blank");
        },
        // 文章收藏
        collectionCollect(state) {
            let id = {
                user_openid: localStorage.getItem("code"),
                article_id: state,
            };
            //   if (state.isCollect == 1) {
            cancel_collect(id)
                .then((res) => {
                    // console.log(res);
                    this.$message({
                        message: "收藏已取消",
                        //   type: "success",
                    });
                    this.collection();
                })
                .catch((err) => {
                    console.log(err);
                });
            //   }
            //   this.getList();
        },
        // 翻页
        handleCurrentChange01(val) {
            this.colList.page = val;
            this.collection();
            //   window.scrollTo(0, 0);
        },
        // 翻页
        handleCurrentChange02(val) {
            this.email_pag.page = val;
            this.collection();
            //   window.scrollTo(0, 0);
        },
        // 更新
        goDetail(data) {
            // console.log(data);
            if (data.isReads == "1") {
                let update_id = {
                    user_openid: localStorage.getItem("code"),
                    journal_id: data.id,
                };
                reads(update_id)
                    .then((res) => {
                        // console.log(res);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            this.$router.push({
                path: "/journalsDetail",
                query: { id: data.id, subject: data.categories },
            });
        },
        update() {
            let user_id = {
                user_openid: localStorage.getItem("code"),
            };
            update_article(user_id)
                .then((res) => {
                    // console.log("id",res);
                    this.total = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        allReads() {
            let user_id = {
                user_openid: localStorage.getItem("code"),
            };
            been_read(user_id)
                .then((res) => {
                    // console.log(res);
                    this.update();
                    //   this.total = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleClick(tab, event) {
            // console.log(tab.index);
            window.sessionStorage.setItem("el_tabs_index", tab.index);
        },
    },
};
</script>

<style lang="less" scoped>
.subscribe {
    //   background: white;
    min-height: calc(100vh - 180px);

    .checks {
        min-width: 1200px;
        max-width: 1550px;
        margin: auto;
        // margin: 10px 20px;
        padding-top: 50px;
        background: white;
        padding: 0 20px 20px 20px;
        min-height: calc(100vh - 300px);

        // display: flex;
        // justify-content: center;
        // align-items: center;
        h3 {
            font-size: 21px;
        }

        .journal_list {
            margin: 20px 0;
            display: flex;
            padding: 0 5%;

            // justify-content: space-between;
            .journal_img {
                margin-right: 50px;
                position: relative;

                img {
                    width: 150px;
                    height: 205px;
                    border-radius: 5px;
                }

                .item {
                    width: 16px;
                    height: 16px;
                    background: red;
                    border-radius: 8px;
                    position: relative;
                    top: -215px;
                    left: 165px;
                }
            }

            .journal_data {
                width: 100%;

                //  max-width: 800px;
                // min-width: 500px;
                .title {
                    width: 100%;
                    // min-width: 300px;
                    display: flex;

                    justify-content: space-between;
                }

                h2:hover {
                    color: #a0a0a0;
                    cursor: pointer;
                }

                .tags {
                    display: flex;
                    margin: 10px 0;

                    .el-tag {
                        margin-right: 10px;
                    }
                }

                p {
                    line-height: 30px;
                }
            }
        }

        .page-bar {
            padding: 10px 0;
            display: flex;
            justify-content: center;
        }
    }

    .proof {
        min-width: 1200px;
        max-width: 1550px;
        margin: auto;
        // margin: 30px 20px 10px 20px;
        min-height: calc(100vh - 300px);
        padding-top: 50px;
        background: white;
        padding: 0 20px 20px 20px;

        // display: flex;
        // justify-content: center;
        // align-items: center;
        h3 {
            font-size: 21px;
        }
    }
}

.articleList {
    padding: 0 5%;

    .article_list {
        //   margin: 20px 0;
        margin-bottom: 10px;
        padding: 10px;
        border-radius: 5px;

        // background: #eeeeee;
        .title {
            width: 100%;
            // min-width: 300px;
            display: flex;

            justify-content: space-between;

            img {
                width: 30;
                cursor: pointer;
            }

            h2:hover {
                color: rgb(182, 182, 182);
                cursor: pointer;
            }

            h2 {
                line-height: 32px;
                cursor: pointer;
            }

            img {
                width: 30px;
                cursor: pointer;
            }
        }

        h2 {
            font-size: 18px;
            font-weight: 500;
        }

        h4 {
            font-size: 18px;
            font-weight: 500;
        }

        p {
            line-height: 30px;
            margin: 10px;

            img {
                width: 30px;
                position: relative;
                top: 10px;
                margin-right: 10px;
            }

            span {
                display: inline-block;
                line-height: 30px;
                //   text-indent:2em;
            }

            .more_text {
                font-weight: 700;
            }

            .more_text:hover {
                color: #9be0fc;
                cursor: pointer;
            }
        }

        .abstract {
            text-indent: 2em;
            overflow: hidden;
            -webkit-line-clamp: 2.5;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
    }

    .page-bar {
        margin: 10px 0;
        display: flex;
        justify-content: center;
    }
}

.not {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 450px;
        height: 400px;
    }
}

.subscribe_css {
    width: 50px !important;
    height: 50px !important;
    position: relative;
    left: 100px;
    top: -205px;
}
</style>